import styled, { css } from 'styled-components';

export interface ButtonProps {
  width?: string;
  bold?: boolean;
}

const buttonStyle = css<ButtonProps>`
  border: none;
  padding: 15px 60px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: ${({ theme }) => theme.typo.sm};
  color: white;
  width: ${({ width }) => (width ? width : 'auto')};
  text-align: center;
`;

export const BlueCTA = css`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.color.dark_blue.hex};
  &:hover {
    background-color: transparent;
    border: solid 1px ${({ theme }) => theme.color.dark_blue.hex};
    color: ${({ theme }) => theme.color.dark_blue.hex};
    margin: -1px;
  }
`;
export const OrangeCTA = css`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.color.orange.hex};
  &:hover {
    background-color: transparent;
    border: solid 1px ${({ theme }) => theme.color.orange.hex};
    color: ${({ theme }) => theme.color.orange.hex};
    margin: -1px;
  }
`;
export const WhiteCTA = css`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.color.white.hex};
  color: ${({ theme }) => theme.color.grey.hex};
  &:hover {
    background-color: transparent;
    border: solid 1px ${({ theme }) => theme.color.white.hex};
    color: ${({ theme }) => theme.color.white.hex};
    margin: -1px;
  }
`;

export const OrangeButton = styled.button<ButtonProps>`
  ${OrangeCTA}
`;
export const OrangeLink = styled.a<ButtonProps>`
  ${OrangeCTA}
`;

export const BlueButton = styled.button<ButtonProps>`
  ${BlueCTA}
`;
export const BlueLink = styled.a<ButtonProps>`
  ${BlueCTA}
`;

export const WhiteButton = styled.button<ButtonProps>`
  ${WhiteCTA}
`;

export const WhiteLink = styled.a<ButtonProps>`
  ${WhiteCTA}
`;
